<template>
  <Layout>
    <h3>Challenges</h3>
    <div class="right-align m-2">
      <b-button variant="primary" @click="modalShow = !modalShow">
        <i class="fas fa-user-plus"></i><span class="pl-2">Add Challenges</span>
      </b-button>
    </div>
    <div style="text-align: center; margin: 28px" v-if="dataLoading">
      <pulse-loader color="#e83e8c" />
    </div>
    <div class="row">
      <div class="col-md-6 col-lg-4" v-for="item in items" :key="item._id">
        <div
          class="card bg-image cardb"
          :style="{
            'background-image': 'url(' + item.image + ')',
            backgroundPosition: 'center',
          }"
        >
          <div class="card-body" style="background-color: rgba(0, 0, 0, 0.5)">
            <div class="media d-flex justify-content-between">
              <h2 style="color: #fa9f73">{{ item.name }}</h2>
              <b-dropdown
                right
                toggle-class="arrow-none card-drop"
                class="float-right"
                variant="white"
                style="margin-right: -22px; margin-top: -8px"
              >
                <template v-slot:button-content>
                  <i class="ri-settings-5-line"></i>
                </template>
                <b-dropdown-item @click="editTribeDetails(item._id)"
                  >Edit</b-dropdown-item
                >
                <b-dropdown-item @click="deleteModalOpen(item._id)">
                  Delete</b-dropdown-item
                >
              </b-dropdown>
            </div>
            <h6 class="mt-2" style="color: white">Value: {{ item.value }}</h6>
            <h6 style="color: white">sort value : {{ item.sort_value }}</h6>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="deleteModal" @ok="handleDelete">
      Are you sure you want to delete this challenge?
    </b-modal>
    <b-modal v-model="modalShow" hide-footer>
      <FormulateForm @submit="handleSubmit" class="mb-4">
        <FormulateInput
          v-model="name"
          validation="required"
          name="name"
          type="text"
          label="Challenge Name"
        />
        <FormulateInput
          v-model="name_ar"
          name="name_ar"
          type="text"
          label="Challenge Name(AR)"
        />

        <FormulateInput
          v-model="description"
          name="description"
          type="text"
          label="Challenge Description"
        />
        <FormulateInput
          v-model="description_ar"
          name="description_ar"
          type="text"
          label="Challenge Desc(AR)"
        />

        <FormulateInput
          v-model="sort_value"
          name="sort_value"
          validation="required"
          type="number"
          label="Sort Value"
        />
        <FormulateInput
          v-model="images"
          name="image"
          validation="required"
          type="image"
          label="Image"
        />
        <FormulateInput
          v-model="value"
          name="value"
          validation="required"
          type="text"
          label="Value"
        />
        <!-- <FormulateInput
          v-model="value_ar"
          name="value_ar"
          type="text"
          label="Value(AR)"
        /> -->
        <b-button variant="primary" type="submit" class="m-auto"
          >Submit</b-button
        >
      </FormulateForm>
    </b-modal>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import { challenges } from "@/config/api/challenges";

export default {
  data() {
    return {
      items: [],
      modalShow: false,
      deleteModal: false,
      sort_value: 1,
      value: "",
      // value_ar: "",
      options: [
        { text: "1", value: 1 },
        { text: "2", value: 2 },
        { text: "3", value: 3 },
        { text: "4", value: 4 },
        { text: "5", value: 5 },
        { text: "6", value: 6 },
        { text: "7", value: 7 },
        { text: "8", value: 8 },
        { text: "9", value: 9 },
        { text: "10", value: 10 },
      ],
      dataLoading: false,
      name: "",
      name_ar: "",
      description: "",
      description_ar: "",
      deleteId: "",
      images: null,
    };
  },
  components: {
    Layout,
  },
  mounted() {
    this.dataLoading = true;

    const api = challenges.getChallenges;
    this.loading = true;
    this.generateAPI(api)
      .then((res) => {
        this.items = res.data;
        console.log(this.items, "data");
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.dataLoading = false;
      });
  },
  methods: {
    async handleDelete() {
      try {
        let api = challenges.deleteChallenges;
        api.id = this.deleteId;
        const res = await this.generateAPI(api);
        if (res) {
          this.$bvToast.toast(res?.data?.message, {
            title: "Challenges",
            variant: "sucess",
            solid: true,
          });
        }
      } catch (error) {
        this.$bvToast.toast(error?.message, {
          title: "Challenges",
          variant: "error",
          solid: true,
        });
      } finally {
        window.location.reload();
      }
    },
    async handleSubmit() {
      const formData = new FormData();
      formData.append("name", this.name);
      formData.append("name_ar", this.name_ar);
      formData.append("description", this.description);
      formData.append("description_ar", this.description_ar);
      formData.append("value", this.value);
      // formData.append("value_ar", this.value_ar);

      formData.append("sort_value", this.sort_value);
      if (this.images) {
        formData.append("file", this.images?.files[0]?.file);
      }
      let api = challenges.updateChallenges;
      //   api.id = this.$route.params.id;/*  */
      api.data = formData;
      this.dataLoading = true;
      await this.generateAPI(api)
        .then((res) => {
          this.form = res.data;
        })
        .catch((err) => {
          console.log(err);
          this.$bvToast.toast(err?.message, {
            title: "Challenges",
            variant: "error",
            solid: true,
          });
        })
        .finally(() => {
          this.dataLoading = false;
          this.modalShow = false;
          window.location.reload();
        });
    },
    editTribeDetails(id) {
      this.$router.push({ name: "editChallenge", params: { id: id } });
    },
    deleteModalOpen(id) {
      this.deleteId = id;
      this.deleteModal = !this.deleteModal;
    },
  },
};
</script>

<style>
.cardb {
  min-width: 18rem;
  height: 10rem;
}

.bg-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
</style>
